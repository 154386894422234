import { isSSR } from "../utils/SSRUtil";

export const FacebookService = {
  appId: import.meta.env.VITE_APP_FACEBOOK_APP_ID,
  init() {
    if (isSSR()) {
      return;
    }

    if (!this.appId) {
      this.log(`Facebook SDK is being disabled in .env file, we'll not start Facebook SDK`);
      return;
    }

    window.fbAsyncInit = function () {
      window?.FB.init({
          appId: this.appId,
          xfbml : true,
          version : 'v19.0',
      });
    };

      // load facebook sdk script
      (function (d, s, id) {
          var js, fjs = d.getElementsByTagName(s)[0];
          if (d.getElementById(id)) { return; }
          js = d.createElement(s); js.id = id;
          js.src = "https://connect.facebook.net/en_US/sdk.js";
          fjs.parentNode.insertBefore(js, fjs);
      }(document, 'script', 'facebook-jssdk'));  

    this.log(`Facebook SDK is initialized with app id ${this.appId}`);
  },

  log(message, meta) {
    if (meta !== undefined) {
      console.log(`Facebook: ${message}`, meta);
    } else {
      console.log(`Facebook: ${message}`);
    }
  },
  share: (
    url,
  ) => {
    window?.FB.ui({
      method: 'feed',
      link: url,
    });
  },
};
