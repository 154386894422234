import { states } from "./states";

export const TILT_TRAILERS_NAME = "Tilt Trailers";

export const trailerTypes = [
  {
    type_id: 1,
    name: "general-trailer",
    suffix: "trailer-sales",
    category: "General Trailers",
    categoryGroupPath: "trailers-for-sale",
  },
  {
    type_id: 2,
    name: "horse-and-livestock-trailer",
    suffix: "trailer-sales",
    category: "Horse & Livestock",
    categoryGroupPath: "horse-and-livestock-trailers-for-sale",
  },
  {
    type_id: 3,
    name: "rv-and-travel-trailer",
    suffix: "rv-and-trailer-sales",
    category: "Travel Trailers",
    categoryGroupPath: "all-travel-trailers-for-sale",
  },
  {
    type_id: 4,
    name: "semi-trailer",
    suffix: "semi-trailer-sales",
    category: "Semi Trailers",
    categoryGroupPath: "semi-trailers-for-sale",
  },
  {
    type_id: 5,
    name: "truckbed",
    suffix: "truckbed-sales",
    category: "Truck Beds",
    categoryGroupPath: "truck-beds-for-sale",
  },
];

// Custom sort order for categories go here
export const sortOrder = {
  // General Trailers
  1: [
    "Cargo (Enclosed) Trailers",
    "Equipment / Flatbed Trailers",
    "Car Haulers / Racing Trailers",
    "Utility Trailers",
    "Tilt Trailers",
    "Motorcycle / Cycle Trailers",
    "ATV Trailers",
    "Watercraft Trailers",
    "Snowmobile Trailers",
    "Tow Dollys",
    "Dump Trailers",
    "Vending / Concession Trailers",
    "Office / Fiber Optic Trailers",
    "Other Trailers",
    "Cargo (Enclosed) Trailers",
    "Equipment / Flatbed Trailers",
    "Car Haulers / Racing Trailers",
    "Tow Dollys",
    "Tilt Trailers",
    "Motorcycle / Cycle Trailers",
    "ATV Trailers",
    "Watercraft Trailers",
    "Snowmobile Trailers",
    "Utility Trailers",
    "Dump Trailers",
    "Vending / Concession Trailers",
    "Office / Fiber Optic Trailers",
    "Other Trailers",
  ],
  // Horse & Livestock
  2: [],
  //Travel Trailers
  3: [],
  //Semi Trailers
  4: [
    "Low Boy / Drop Deck Semi Trailers",
    "Dry Van Semi Trailers",
    "Flatbed Semi Trailers",
    "Grain Semi Trailers",
    "Reefer Semi Trailers",
    "Livestock Semi Trailers",
    "Tank / Bulk Semi Trailers",
    "Dump Semi Trailers",
    "Semi Car Hauler",
    "Other Semi Trailers",
    "Other Trucks",
    "Standard Trucks",
  ],
  //Truck Beds
  5: [],
};

export const tcCategoryMapping = {
  cargo_enclosed: {
    category: "Cargo (Enclosed) Trailers",
    type_id: 1,
  },
  equipment: {
    category: "Equipment / Flatbed Trailers",
    type_id: 1,
  },
  flatbed: {
    category: "Equipment / Flatbed Trailers",
    type_id: 1,
  },
  landscape: {
    category: "Equipment / Flatbed Trailers",
    type_id: 1,
  },
  deckover: {
    category: "Equipment / Flatbed Trailers",
    type_id: 1,
  },
  gooseneck_bodies: {
    category: "Goose Bodies",
    type_id: 5,
  },
  car_racing: {
    category: "Car Haulers / Racing Trailers",
    type_id: 1,
  },
  stacker: {
    category: "Car Haulers / Racing Trailers",
    type_id: 1,
  },
  "trailer.car_hauler": {
    category: "Car Haulers / Racing Trailers",
    type_id: 1,
  },
  car_hauler: {
    category: "Car Haulers / Racing Trailers",
    type_id: 1,
  },
  tow_dolly: {
    category: "Tow Dollys",
    type_id: 1,
  },
  motorcycle: {
    category: "Motorcycle / Cycle Trailers",
    type_id: 1,
  },
  atv: {
    category: "ATV Trailers",
    type_id: 1,
  },
  watercraft: {
    category: "Watercraft Trailers",
    type_id: 1,
  },
  boat_trailer: {
    category: "Watercraft Trailers",
    type_id: 1,
  },
  personal_watercraft: {
    category: "Watercraft Trailers",
    type_id: 1,
  },
  snowmobile: {
    category: "Snowmobile Trailers",
    type_id: 1,
  },
  utility: {
    category: "Utility Trailers",
    type_id: 1,
  },
  multisport: {
    category: "Utility Trailers",
    type_id: 1,
  },
  dump: {
    category: "Dump Trailers",
    type_id: 1,
  },
  dump_bin: {
    category: "Dump Trailers",
    type_id: 1,
  },
  rollster: {
    category: "Dump Trailers",
    type_id: 1,
  },
  vending_concession: {
    category: "Vending / Concession Trailers",
    type_id: 1,
  },
  office: {
    category: "Office / Fiber Optic Trailers",
    type_id: 1,
  },
  fiber_splicing: {
    category: "Office / Fiber Optic Trailers",
    type_id: 1,
  },
  contractor: {
    category: "Office / Fiber Optic Trailers",
    type_id: 1,
  },
  other: {
    category: "Other Trailers",
    type_id: 1,
  },
  trailer_fuel: {
    category: "Other Trailers",
    type_id: 1,
  },
  Pressure_Washer: {
    category: "Other Trailers",
    type_id: 1,
  },
  refrigerated: {
    category: "Other Trailers",
    type_id: 1,
  },
  specialty: {
    category: "Other Trailers",
    type_id: 1,
  },
  pipe: {
    category: "Other Trailers",
    type_id: 1,
  },
  trash: {
    category: "Other Trailers",
    type_id: 1,
  },
  bbq: {
    category: "Other Trailers",
    type_id: 1,
  },
  equip_fuel_solutions: {
    category: "Other Trailers",
    type_id: 1,
  },
  restroom_shower: {
    category: "Other Trailers",
    type_id: 1,
  },
  equip_hay_forage: {
    category: "Other Trailers",
    type_id: 1,
  },
  semi_tilt: {
    category: "Tilt Trailers",
    type_id: 1,
  },
  trailer_tilt: {
    category: "Tilt Trailers",
    type_id: 1,
  },
  horse: {
    category: "Horse Trailers",
    type_id: 2,
  },
  equip_livestock: {
    category: "Livestock Semi Trailers",
    type_id: 4,
  },
  "stock_stock-combo": {
    category: "Stock / Stock Combo Trailers",
    type_id: 2,
  },
  stock: {
    category: "Stock / Stock Combo Trailers",
    type_id: 2,
  },
  hay: {
    category: "Stock / Stock Combo Trailers",
    type_id: 2,
  },
  tiny_house: {
    category: "Travel Trailers",
    type_id: 3,
  },
  camping_rv: {
    category: "Travel Trailers",
    type_id: 3,
  },
  "tent-camper": {
    category: "Travel Trailers",
    type_id: 3,
  },
  camper_popup: {
    category: "Travel Trailers",
    type_id: 3,
  },
  destination_trailer: {
    category: "Travel Trailers",
    type_id: 3,
  },
  expandable: {
    category: "Travel Trailers",
    type_id: 3,
  },
  camper_aframe: {
    category: "Travel Trailers",
    type_id: 3,
  },
  fish_house: {
    category: "Travel Trailers",
    type_id: 3,
  },
  rv_other: {
    category: "Travel Trailers",
    type_id: 3,
  },
  park_model: {
    category: "Travel Trailers",
    type_id: 3,
  },
  camper_teardrop: {
    category: "Travel Trailers",
    type_id: 3,
  },
  "ice-fish_house": {
    category: "Travel Trailers",
    type_id: 3,
  },
  ice_shack: {
    category: "Travel Trailers",
    type_id: 3,
  },
  fifth_wheel_campers: {
    category: "Fifth Wheel Trailers",
    type_id: 3,
  },
  toy: {
    category: "Toy Haulers",
    type_id: 3,
  },
  offroad: {
    category: "Camper / RV",
    type_id: 3,
  },
  class_a: {
    category: "Camper / RV",
    type_id: 3,
  },
  class_b: {
    category: "Camper / RV",
    type_id: 3,
  },
  class_bplus: {
    category: "Camper / RV",
    type_id: 3,
  },
  class_c: {
    category: "Camper / RV",
    type_id: 3,
  },
  semi_detach: {
    category: "Low Boy / Drop Deck Semi Trailers",
    type_id: 4,
  },
  semi_double: {
    category: "Low Boy / Drop Deck Semi Trailers",
    type_id: 4,
  },
  semi_lowboy: {
    category: "Low Boy / Drop Deck Semi Trailers",
    type_id: 4,
  },
  semi_drop: {
    category: "Low Boy / Drop Deck Semi Trailers",
    type_id: 4,
  },
  semi_container: {
    category: "Dry Van Semi Trailers",
    type_id: 4,
  },
  semi_curtainside: {
    category: "Dry Van Semi Trailers",
    type_id: 4,
  },
  "semi_drop-van": {
    category: "Dry Van Semi Trailers",
    type_id: 4,
  },
  semi_dryvan: {
    category: "Dry Van Semi Trailers",
    type_id: 4,
  },
  semi_flatbed: {
    category: "Flatbed Semi Trailers",
    type_id: 4,
  },
  semi_highboy: {
    category: "Flatbed Semi Trailers",
    type_id: 4,
  },
  semi_belt: {
    category: "Grain Semi Trailers",
    type_id: 4,
  },
  "semi_grain-hopper": {
    category: "Grain Semi Trailers",
    type_id: 4,
  },
  semi_hopper_trailers: {
    category: "Grain Semi Trailers",
    type_id: 4,
  },
  semi_reefer: {
    category: "Reefer Semi Trailers",
    type_id: 4,
  },
  semi_horse: {
    category: "Livestock Semi Trailers",
    type_id: 4,
  },
  semi_livestock: {
    category: "Livestock Semi Trailers",
    type_id: 4,
  },
  semi_bulk: {
    category: "Tank / Bulk Semi Trailers",
    type_id: 4,
  },
  semi_tanker: {
    category: "Tank / Bulk Semi Trailers",
    type_id: 4,
  },
  tank_trailer: {
    category: "Tank / Bulk Semi Trailers",
    type_id: 4,
  },
  semi_dump: {
    category: "Dump Semi Trailers",
    type_id: 4,
  },
  semi_car_trailer: {
    category: "Semi Car Hauler",
    type_id: 4,
  },
  semi_btrain: {
    category: "Other Semi Trailers",
    type_id: 4,
  },
  semi_dolley: {
    category: "Other Semi Trailers",
    type_id: 4,
  },
  semi_livefloor: {
    category: "Other Semi Trailers",
    type_id: 4,
  },
  semi_log: {
    category: "Other Semi Trailers",
    type_id: 4,
  },
  semi_other: {
    category: "Other Semi Trailers",
    type_id: 4,
  },
  semitruck_tanker_truck: {
    category: "Other Trucks",
    type_id: 4,
  },
  semitruck_flatbed_truck: {
    category: "Other Trucks",
    type_id: 4,
  },
  semitruck_dump_truck: {
    category: "Other Trucks",
    type_id: 4,
  },
  semitruck_other: {
    category: "Other Trucks",
    type_id: 4,
  },
  semitruck_offroad: {
    category: "Other Trucks",
    type_id: 4,
  },
  semitruck_highway: {
    category: "Other Trucks",
    type_id: 4,
  },
  semitruck_heavy: {
    category: "Other Trucks",
    type_id: 4,
  },
  vehicle_truck: {
    category: "Other Trucks",
    type_id: 4,
  },
  semitruck_standard: {
    category: "Standard Trucks",
    type_id: 4,
  },
  bed_equipment: {
    category: "Flat Decks",
    type_id: 5,
  },
  dump_insert: {
    category: "Truck Beds Category",
    type_id: 5,
  },
  kuv_bodies: {
    category: "Truck Beds Category",
    type_id: 5,
  },
  platform_bodies: {
    category: "Truck Beds Category",
    type_id: 5,
  },
  saw_bodies: {
    category: "Truck Beds Category",
    type_id: 5,
  },
  truck_cap: {
    category: "Truck Beds Category",
    type_id: 5,
  },
  van_bodies: {
    category: "Truck Beds Category",
    type_id: 5,
  },
  stake_bodies: {
    category: "Stake Bodies",
    type_id: 5,
  },
  dump_bodies: {
    category: "Dump Bodies",
    type_id: 5,
  },
  service_bodies: {
    category: "Service Bodies",
    type_id: 5,
  },
  truck_bodies: {
    category: "Truck Beds Category",
    type_id: 5,
  },
  truck_boxes: {
    category: "Truck Beds Category",
    type_id: 5,
  },
  "rv.truck_camper": {
    category: "Truck Bed Campers",
    type_id: 3,
  },
  truck_camper: {
    category: "Truck Bed Campers",
    type_id: 3,
  },
  overland: {
    category: "Overland Trailers",
    type_id: 3,
  },
  "Implement": {
    category: "Implement",
    type_id: 1,
  },
};

export const dealerRoutes = () => {
  return trailerTypes.map(({ type_id: typeId, name, suffix }) => {
    return {
      path: `/${name}-dealer-in-:city-:state/:dealerName*-${suffix}`,
      component: () => import("../views/DealerSales.vue"),
      name: `DealerSales-${name}`,
      meta: {
        title: "TrailerTrader | More from Dealers",
        type_id: typeId,
        gtm: "Trailer & RV sellers on TrailerTrader",
      },
    };
  });
};

export const dealerListRoutes = () => {
  return (Object.keys(states || {}).map((state) => {
    return [{ name: null }, ...trailerTypes].map(({ name }) => {
      return {
        path: name !== null ? `/${name}-dealers-in-${state}` : `/dealers-in-${state}`,
        component: () => import("../views/DealerList.vue"),
        name: name !== null ? `DealerList-${name}-in-${state}` : `DealerList-in-${state}`,
      };
    });
  }) || []).flat();
};
