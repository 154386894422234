<template>
  <ion-grid
    v-if="pageLoaded"
    class="footer-background"
    style="width: 100%"
  >
    <h2 class="ion-text-center hyperlink-title content-container">
      Most popular searches on Trailertrader
    </h2>
    <div
      class="popular-footer content-container row-spacing"
    >
      <template
        v-for="(data, index) in popularUrl"
        :key="index"
      >
        <a
          v-for="(value, childIndex) in popularUrl[index]"
          :id="String(value.name).replace(' ', '_')"
          :key="childIndex"
          class="popular-btn"
          :title="value.description"
          :href="`${domainOrigin}${value.url}`"
          @click="redirect(value.url, value.query, $event)"
        >{{ value.name }}</a>
      </template>
    </div>
  </ion-grid>
  <ion-footer
    v-if="pageLoaded"
    class="ion-no-border footer-background"
    :style="footerPadding"
  >
    <ion-grid class="ion-no-padding">
      <ion-row class="row-spacing ion-wrap">
        <ion-col size="12">
          <ion-toolbar>
            <div class="ion-no-padding-md">
              <img
                alt="TrailerTraderLogo"
                class="trailer-trader-logo"
                src="@/assets/TrailerTraderLogo.webp"
                width="300"
              >
            </div>
          </ion-toolbar>
        </ion-col>
        <ion-col
          size="12"
          size-md="12"
          size-lg="4"
          class="info-w"
        >
          <div class="ion-text-start text-container info-container">
            <p>WE ARE AMERICA’S ONLINE TRAILER MARKETPLACE</p>
            <p> When it comes to buying, selling, and learning about trailers, there’s no better place than TrailerTrader. We’ve been America’s choice for decades. </p>
          </div>
        </ion-col>
        
        <ion-col
          class="ion-no-padding"
          size="12"
          size-md="12"
          size-lg="8"
        >
          <ion-row class="footer-links">
            <div>
              <ion-row>
                <ion-toolbar>
                  <div class="ion-text-uppercase text-container header">
                    Buy
                  </div>
                  <a
                    id="buynewtrailers_footer"
                    :href="`${domainOrigin}/trailers-for-sale/all-trailers-for-sale?distance=100mi&condition=new`"
                    class="text-container sub-header"
                    @click="gotoNewTrailers($event)"
                  >
                    New Trailers
                  </a>
                  <a
                    id="buypreownedtrailers_footer"
                    :href="`${domainOrigin}/trailers-for-sale/all-used-trailers-for-sale?distance=100mi&condition=remfg`"
                    class="text-container sub-header"
                    @click="gotoPreOwnedTrailers($event)"
                  >
                    Pre-Owned Trailers
                  </a>
                  <a
                    id="bymanufacturer_footer"
                    :href="`${domainOrigin}/manufacturers-on-trailertrader`"
                    class="text-container sub-header"
                    @click="gotoManufacturer($event)"
                  >
                    By Manufacturer
                  </a>
                  <a
                    v-for="(type, index) in trailerTypes"
                    :key="type"
                    :href="`${domainOrigin}/find-${type}-near-you`"
                    class="text-container sub-header"
                  >
                    {{ trailerTypeTitles[index + 1] }} Near You
                  </a>
                </ion-toolbar>
              </ion-row>
            </div>
            <div>
              <ion-row>
                <ion-toolbar>
                  <div class="ion-text-uppercase text-container header">
                    SELL on TRAILERTRADER
                  </div>
                  <a
                    v-if="isAuthenticated"
                    id="privateseller_sell_footer"
                    :href="`${domainOrigin}/dashboard`"
                    class="text-container sub-header"
                    @click="gotoPage('/dashboard', $event)"
                  >
                    Dashboard
                  </a>
                  <a
                    v-if="!isAuthenticated"
                    id="privateseller_sell_footer"
                    :href="`${domainOrigin}/privatesellers`"
                    class="text-container sub-header"
                    @click="gotoPage('/privatesellers', $event)"
                  >
                    as a PRIVATE SELLER
                  </a>
                  <a
                    v-if="!isAuthenticated"
                    id="dealers_sell_footer"
                    :href="`${domainOrigin}/dealers`"
                    class="text-container sub-header"
                    @click="gotoPage('/dealers', $event)"
                  >
                    as a DEALER
                  </a>
                </ion-toolbar>
              </ion-row>
            </div>
            <div>
              <ion-row>
                <ion-toolbar>
                  <div class="ion-text-uppercase header">
                    Help
                  </div>
                  <a
                    id="faq_footer"
                    :href="`${domainOrigin}/faq`"
                    class="sub-header"
                    @click="gotoPage('/faq', $event)"
                  >
                    FAQ
                  </a>
                  <a
                    id="guide_footer"
                    :href="`${domainOrigin}/articles/what-features-should-you-consider-when-buying-a-trailer`"
                    class="sub-header"
                    @click="gotoPage('/articles/what-features-should-you-consider-when-buying-a-trailer', $event)"
                  >
                    Buyer's Guide
                  </a>
                  <a
                    id="guide_footer"
                    :href="`${domainOrigin}/articles/buyers-guide-for-used-trailers`"
                    class="sub-header"
                    @click="gotoPage('/articles/buyers-guide-for-used-trailers', $event)"
                  >
                    Used Trailers
                  </a>
                  <a
                    id="guide_footer"
                    :href="`${domainOrigin}/articles/tow-guide`"
                    class="sub-header"
                    @click="gotoPage('/articles/tow-guide', $event)"
                  >
                    Tow Guide
                  </a>
                  <a
                    id="guide_footer"
                    :href="`${domainOrigin}/articles/ask-for-vin-and-check`"
                    class="sub-header"
                    @click="gotoPage('/articles/ask-for-vin-and-check', $event)"
                  >
                    VIN check
                  </a>
                  <a
                    id="abouttt_footer"
                    :href="`${domainOrigin}/about`"
                    class="sub-header"
                    @click="gotoPage('/about', $event)"
                  >
                    About TrailerTrader
                  </a>
                </ion-toolbar>
              </ion-row>
            </div>
            <div>
              <ion-row>
                <ion-toolbar>
                  <div class="ion-text-uppercase header">
                    Affiliates
                  </div>
                  <div
                    v-for="affiliate in affiliates"
                    :key="affiliate.text"
                    class="ion-text-start"
                  >
                    <a
                      :id="affiliate.id"
                      :href="affiliate.href"
                      class="hide-underline sub-header"
                      target="_blank"
                    >
                      {{ affiliate.text }}
                    </a>
                  </div>
                </ion-toolbar>
              </ion-row>
            </div>
          </ion-row>
        </ion-col>
      </ion-row>
      <ion-row class="row-spacing content-container">
        <ion-toolbar>
          <!--Commenting out this div, will to reopen it after marketing allocates time for editorial -->
          <!--<div class="social-icon-btn">
            <a
              id="facebook_footer"
              href="https://www.facebook.com/TrailerTraderClassifieds/?__nodl&refsrc=deprecated&_rdr"
              target="_blank"
            ><img
              width="9"
              height="20"
              alt="facebookIcon"
              src="@/assets/facebookIcon.svg"
            ></a>
            <a
              id="instagram_footer"
              href="https://www.instagram.com/trailertrader/"
              target="_blank"
            ><img
              width="20"
              height="20"
              alt="instagramIcon"
              src="@/assets/instagramIcon.svg"
            ></a>
            <a
              id="linkedin_footer"
              href="https://www.linkedin.com/company/trailercentral/"
              target="_blank"
            ><img
              width="20"
              height="20"
              alt="instagramIcon"
              src="@/assets/linkedinIcon.svg"
            ></a>
          </div>-->
        </ion-toolbar>
      </ion-row>
      <ion-row class="sub-footer">
        <ion-col>
          <ion-row class="row-spacing">
            <ion-col
              size-sm="12"
              size-md="6"
            >
              <img
                src="@/assets/footer-graphic.webp"
                alt=""
              >
            </ion-col>
            <ion-col
              size="12"
              size-md="6"
              class="ion-padding-end ion-align-self-end"
            >
              <div class="footer-copyright">
                <div>
                  <a
                    id="privacypolicy_footer"
                    :href="`${domainOrigin}/privacypolicy`"
                    class="sub-footer-container"
                    style="cursor: pointer"
                    @click="gotoPage('/privacypolicy', $event)"
                  >
                    Privacy Policy
                  </a>

                  <a
                    id="termsofuse_footer"
                    :href="`${domainOrigin}/termsofuse`"
                    class="sub-footer-container"
                    style="cursor: pointer"
                    @click="gotoPage('/termsofuse', $event)"
                  >
                    Terms of Use
                  </a>

                  <div
                    id="sitemap_footer"
                    class="sub-footer-container"
                  >
                    <a :href="`https://${parentUrl}/sitemap.xml`"> Sitemap </a>
                  </div>
                </div>
                <div class="copyright">
                  <p class="ion-text-center">
                    &copy; TrailerTrader {{ new Date().getFullYear() }}, all rights reserved
                  </p>
                </div>
              </div>
            </ion-col>
          </ion-row>
        </ion-col>
      </ion-row>
    </ion-grid>
    <!-- End Desktop view -->
  </ion-footer>
</template>

<script>
import { IonFooter, IonToolbar, IonGrid, IonCol, IonRow } from "@ionic/vue";
import { mapActions, mapState } from "vuex";
import GroupItemFilter from "@/models/filters/group/GroupItemFilter";
import { POPULAR_URL } from "@/consts/popularUrl";
import isEqual from "lodash/isEqual";
import { types as TrailerTypes } from "@/utils/UrlParamUtil";
import { TYPE_TITLES as TrailerTypeTitles } from "@/consts/category";
import { domain } from "@/mixins";

export default {
  components: {
    IonFooter,
    IonToolbar,
    IonGrid,
    IonCol,
    IonRow,
  },
  mixins: [domain],
  emits: ["scrollToTop"],
  data() {
    return {
      trailerTypes: TrailerTypes,
      trailerTypeTitles: TrailerTypeTitles,
      affiliates: [
        {
          text: "HorseTrailers",
          href: "https://www.horsetrailers.net/",
          id: "affiliate_horsetrailer_footer",
        },
        {
          text: "SemiTrailerTrader",
          href: "https://www.semitrailertrader.com/",
          id: "affiliate_semitrailertrader_footer",
        },
        {
          text: "TrailerCentral",
          href: "https://trailercentral.com/",
          id: "affiliate_trailercentral_footer",
        },
        {
          text: "Other affiliate sites",
          href: "/affiliates",
          id: "affiliate_otheraffiliates_footer",
        },
      ],
      popularUrl: POPULAR_URL,
      pageLoaded: false,
    };
  },
  computed: {
    footerPadding() {
      return this.$store.getters.footerPadding;
    },
    ...mapState({
      isAuthenticated: (state) => state.isAuthenticated,
    }),
  },
  mounted() {
    setTimeout(() => {
      this.pageLoaded = true;
    }, 2000);
  },
  methods: {
    ...mapActions(["addSearchFilter", "clearAllSearchFilter"]),
    gotoPage(url, $event) {
      $event.preventDefault();
      this.$emit("scrollToTop");
      window?.dispatchEvent(new Event("scrollToTop"));
      if (this.$route.path !== url) {
        this.$router.push({
          path: url,
        });
      }
    },
    gotoNewTrailers(ev) {
      if (ev) {
        ev.preventDefault();
      }
      this.$emit("scrollToTop");
      window?.dispatchEvent(new Event("scrollToTop"));
      if (this.$route.path !== "/trailers-for-sale") {
        this.$router.push({
          path: "/trailers-for-sale",
          query: {
            type_id : "all",
            condition: "new",
          },
        });
      } else {
        this.clearAllSearchFilter();
        this.$store.commit("SET_SEARCH_API_FIELDS", { type_id: 1 });
        this.addSearchFilter(
          new GroupItemFilter({
            name: "condition",
            value: "new",
          }),
        );
      }
    },
    gotoPreOwnedTrailers(ev) {
      if (ev) {
        ev.preventDefault();
      }
      this.$emit("scrollToTop");
      window?.dispatchEvent(new Event("scrollToTop"));
      // Below lines are commented because they were breaking the functionality of the redirection
      if (this.$route.path !== "/trailers-for-sale") {
        this.$router.push({
          path: "/trailers-for-sale",
          query: {
            type_id : "all",
            condition: "used;remfg",
          },
        });
      } else {
        this.clearAllSearchFilter();
        this.$store.commit("SET_SEARCH_API_FIELDS", { type_id: 1 });
        this.addSearchFilter(
          new GroupItemFilter({
            name: "condition",
            value: "used",
          }),
        );
        this.addSearchFilter(
          new GroupItemFilter({
            name: "condition",
            value: "remfg",
          }),
        );
      }
    },
    gotoManufacturer(ev) {
      if (ev) {
        ev.preventDefault();
      }
      this.$emit("scrollToTop");
      window?.dispatchEvent(new Event("scrollToTop"));
      this.$router.push({
        path: "/manufacturers-on-trailertrader",
      });
    },
    redirect(url, query, $event) {
      $event.preventDefault();
      this.$emit("scrollToTop");
      window?.dispatchEvent(new Event("scrollToTop"));
      if (!isEqual(this.$route.query, { ...query })) {
        this.$router.push({
          path: url,
        });
      }
    },
  },
 
};
</script>
